import React from 'react';
import HeaderSubPage from "./partial/HeaderSubPage";

const Career = () => {
    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Karriere'} subtitle={'Karriere'}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <h1 className="post_list_title">
                                    <span><strong>Touristikfachkräfte (m/w/d) in
                                    Vollzeit</strong></span>
                                </h1>
                                <p>
                                    Zur Abwicklung unserer vielfältigen geführten Reisen in den
                                    Bereichen<br/>-&nbsp;&nbsp;Flussreisen<br/>
                                    -&nbsp;&nbsp;Flugreisen (Mittelstrecke)<br/>
                                    -&nbsp;&nbsp;Kultur- &amp; Eventreisen<br/>
                                    -&nbsp;&nbsp;Erdgebundene Reisen<br/>benötigen wir zum nächstmöglichen Zeitpunkt
                                    in unserer Zentrale in Schöneck<br/>
                                    <br/>
                                    <strong>Touristikfachkräfte (m/w/d) in Vollzeit</strong><br/>
                                    <br/>
                                    <strong>Ihre Qualifikationen:</strong><br/>
                                    ✓&nbsp;Profunde Produktkenntnisse im Bereich geführte Reisen<br/>
                                    ✓&nbsp;Vollblut-Touristiker/in mit Berufserfahrung<br/>
                                    ✓&nbsp;Begeisterung für unsere Reisen und Freude am Umgang mit Kunden<br/>
                                    ✓&nbsp;Verhandlungssicher mit Leistungsträgern mindestens in Deutsch und
                                    Englisch<br/>
                                    ✓&nbsp;Belastbar auch in Stresssituationen<br/>
                                    ✓&nbsp;Serviceorientiert, Team- und Kommunikationsfähig<br/>
                                    ✓&nbsp;Selbstständiges und zielorientiertes Arbeiten<br/>
                                    <br/>
                                    <strong>Ihre Aufgaben:</strong><br/>
                                    ✓&nbsp;Eigenverantwortliche Abwicklung der Reisen in "Ihre" Zielgebiete<br/>
                                    ✓&nbsp;Beratung und Betreuung der Vertriebspartner und Kunden <br/>
                                    ✓&nbsp;Buchungs- und Auslastungsüberwachung für "Ihre" Zielgebiete<br/>
                                    ✓&nbsp;Kontakt in deutscher und englischer Sprache mit den
                                    Leistungsträgern <br/>
                                    <br/>
                                    <strong>Wir bieten</strong> eine verantwortungsbewußte, interessante und
                                    abwechslungsreiche Tätigkeit bei leistungsgerechter Dotierung und einen
                                    zukunftsorientierten Arbeitsplatz mit interessanten Entfaltungsmöglichkeiten.
                                    Wenn Ihnen die Abwicklung von geführten Reisen Spaß macht, und Reisen in die
                                    Zielgebiete für Sie eine willkommene Abwechslung sind, dann freuen wir uns auf
                                    Ihre Bewerbung!</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Career;