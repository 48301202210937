import {allTripTypes} from "../utils/utils";
import React from "react";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TripTypesCarousel = () => {
    const options = {
        autoplay:true,
        autoplayTimeout: 5000,
        autoplayHoverPause:true,
        loop: true,
        margin: 10,
        nav: true,
        navText: ['<i class="lnr lnr-chevron-left"></i>', '<i class="lnr lnr-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1,
            },
            480: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 5,
            },
        },
    };
    return (
        <div className="wrapper-tours-slider wrapper-tours-type-slider">
            <OwlCarousel className="tours-type-slider owl-theme" {...options}>
                {allTripTypes.map((post, i) => (
                    <div className="tours_type_item" key={i}>
                        <a href={'/reisearten/' + post.title}
                           className="tours-type__item__image">
                            <img src={post.image} alt={post.alt}/>
                        </a>
                        <div className="content-item">
                            <div className="item__title">{post.title}</div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </div>
    )
}

export default TripTypesCarousel;