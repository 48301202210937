import CookieConsent from "react-cookie-consent";

const Cookie = ({partner}) => {

    return (
        <CookieConsent
            location="bottom"
            buttonText="Einverstanden"
            cookieName="GlobalisCookieConsent"
            style={{background: "#2B373B"}}
            buttonStyle={{color: "#FFF", fontSize: "17px", background: partner.main_color}}
            expires={150}>
            <h4 style={{color: "#FFF"}}>Cookie Hinweis</h4>
            <p>Um Ihnen ein angenehmes Online-Angebot zu bieten, setzen wir Cookies ein. Durch die weitere Nutzung der
                Website stimmen Sie der Verwendung von Cookies zu. .</p>
            <span style={{fontSize: "15px"}}>Weitere Informationen zu Cookies finden Sie in
                unserer Datenschutzerklärung (<a className="text-white" target="_blank"
                                                 href="/datenschutz">mehr dazu</a>)</span>
        </CookieConsent>
    )
}


export default Cookie;