import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {allTripTypes, fetchDestinations} from "../utils/utils";
import {HashLink} from "react-router-hash-link";
import NavTopBar from "./NavTopBar";

const Header = ({partner, showNavTopBar = false, addClass = 'fixed-top'}) => {
    const location = useLocation();
    const [destinations, setDestinations] = useState([]);
    const [types] = useState(allTripTypes);

    // Call fetchData on component mount
    useEffect(() => {
        fetchDestinations().then((data) => {
            setDestinations(data ?? []);
        });
    }, []);
    return (
        <div>
            {showNavTopBar &&
                <NavTopBar partner={partner}></NavTopBar>
            }
            <header id="masthead" className="site-header sticky_header affix-top">
                <div className="" id="banner">
                    <nav id="navscroll"
                         className={`navbar navbar-expand-lg navbar-light ${addClass}`}>
                        <div className="container-fluid">
                            <a className="navbar-brand" href="/">
                                <img src={`${process.env.PUBLIC_URL }/images/logos/header/${partner.header_logo}`}
                                     alt={partner.name}
                                     className="img-logo"/>
                            </a>
                            <div className="d-flex tools">
                                <button type="button" className="navbar-toggler" data-bs-toggle="modal"
                                        data-bs-target="#favModal">
                                    <i className="fa fa-heart fs-1 "></i>
                                </button>
                                <button type="button" className="navbar-toggler" data-bs-toggle="modal"
                                        data-bs-target="#searchModal">
                                    <i className="fa fa-search fs-1"></i>
                                </button>
                                <button className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#sidebar"
                                        aria-controls="sidebar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                                    <li className={`${location.pathname === "/" ? "current-menu-ancestor" : ""}`}>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className={`${location.pathname === "/reisekalender" ? "current-menu-ancestor" : ""}`}>
                                        <a href="/reisekalender">Reisekalender</a>
                                    </li>
                                    <li className={`menu-item-has-children ${location.pathname.includes("/reiseziele") ? "current-menu-ancestor" : ""}`}>
                                        <Link to="/reiseziele">Reiseziele</Link>
                                        <ul className="sub-menu">
                                            {destinations?.map((post, x) => (
                                                <li key={x}>
                                                    <a href={'/reiseziele/' + encodeURIComponent(post.Kontinent_2012) + '?kontinent=' + post.ID}>{post.Kontinent_2012}</a>
                                                </li>
                                            ))}
                                            <li>
                                                <a href="/reisefinder">Alle Reisen</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={`menu-item-has-children ${location.pathname.includes("/reisearten") ? "current-menu-ancestor" : ""}`}>
                                        <Link to="/reisearten">Reisearten</Link>
                                        <ul className="sub-menu">
                                            {types?.map((post, i) => (
                                                <li key={i}>
                                                    <a href={'/reisearten/' + encodeURIComponent(post.title)}>{post.title}</a>
                                                </li>
                                            ))}
                                            <li>
                                                <a href="/reisefinder">Alle Reisen</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={`menu-item-has-children ${location.pathname.includes("/informationen") ? "current-menu-ancestor" : ""}`}>
                                        <a href="#"> Informationen </a>
                                        <ul className="sub-menu">
                                            <li><Link to="/globalis">Über uns</Link></li>
                                            <li><Link to="/informationen/rkv">Reiseversicherungen</Link></li>
                                            <li><Link to="/informationen/reisebedingungen">Reisebedingungen</Link>
                                            </li>
                                            <li><Link to="/informationen/zahlungsweisen">Zahlungsweisen</Link></li>
                                            <li><HashLink
                                                to="informationen/reisebedingungen#Storno">Stornogebühren</HashLink>
                                            </li>
                                            <li><Link to="/informationen/faq">Oft gestellte Fragen</Link></li>
                                        </ul>
                                    </li>
                                    <li className={`${location.pathname === "/kontakt" ? "current-menu-ancestor" : ""}`}>
                                        <Link to="/kontakt">Kontakt</Link>
                                    </li>
                                </ul>

                                <div className="d-flex tools">
                                    <ul className=" mx-auto mb-2 mb-lg-0">
                                        <li className="d-inline p-2">
                                            <button type="button" className="" data-bs-toggle="modal"
                                                    data-bs-target="#favModal">
                                                <i className="fa fa-heart fs-2 "></i>
                                            </button>

                                        </li>
                                        <li className="d-inline">
                                            <a href="/reisefinder">
                                                <button type="button" className="">
                                                    <i className="fa fa-search fs-2"></i>
                                                </button>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/*<form method="get" className="d-flex w-25" action="/reisefinder">
                                    <div className="input-group mb-3 input-group-lg">
                                        <input type="search" className="form-control" name="s"
                                               placeholder="Reiseziel eingeben ..."/>
                                        <button className="search input-group-text" id="inputGroup-sizing-lg" type="submit"><i
                                            className="fa fa-search"></i></button>
                                    </div>
                                </form>*/}
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="offcanvas offcanvas-start"
                     tabIndex="-1" id="sidebar">
                    <div className="offcanvas-header">
                        <div className="offcanvas-title">
                            <img src={`${process.env.PUBLIC_URL }/images/logos/header/${partner.footer_logo}`}
                                 alt={partner.name}
                                 width="150"
                                 className="img-logo"/>
                        </div>
                        <button type="button"
                                className="btn-close"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close">
                        </button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav">
                            {/* <li className="mt-3">
                                <form method="get" className="d-flex w-100" action="/reisefinder">
                                    <div className="input-group mb-3 input-group-lg">
                                        <input type="search" className="form-control" name="s"
                                               placeholder="Reiseziel eingeben ..."/>
                                        <button className="search input-group-text" id="inputGroup-sizing-lg"
                                                type="submit"><i
                                            className="fa fa-search"></i></button>
                                    </div>
                                </form>
                            </li>*/}
                            <li className={`${location.pathname === "/" ? "current-menu-ancestor" : ""} navbar-nav`}>
                                <a className="nav-link" href="/">Home</a>
                            </li>

                            <li className={`${location.pathname === "/reisekalender" ? "current-menu-ancestor" : ""} navbar-nav`}>
                                <a className="nav-link" href="/reisekalender">Reisekalender</a>
                            </li>
                            <li className={`menu-item-has-children ${location.pathname.includes("/reiseziele") ? "current-menu-ancestor" : ""} navbar-nav`}>
                                <a className="nav-link" href="/reiseziele">Reiseziele</a>
                                <ul className="sub-menu">
                                    {destinations?.map((post, x) => (
                                        <li key={x}>
                                            <a className="nav-link"
                                               href={'/reiseziele/' + encodeURIComponent(post.Kontinent_2012) + '?kontinent=' + post.ID}>{post.Kontinent_2012}</a>
                                        </li>
                                    ))}
                                    <li><a className="nav-link" href="/reisefinder">Alle Reisen</a></li>
                                </ul>
                            </li>

                            <li className={`menu-item-has-children ${location.pathname.includes("/reisearten") ? "current-menu-ancestor" : ""}`}>
                                <a className="nav-link" href="/reisearten">Reisearten</a>
                                <ul className="sub-menu">
                                    {types?.map((post, i) => (
                                        <li key={i}><a
                                            className="nav-link"
                                            href={'/reisearten/' + encodeURIComponent(post.title)}>{post.title}</a>
                                        </li>
                                    ))}
                                    <li><a className="nav-link" href="/reisefinder">Alle Reisen</a></li>
                                </ul>
                            </li>
                            <li className={`menu-item-has-children ${location.pathname.includes("/informationen") ? "current-menu-ancestor" : ""}`}>
                                <a className="nav-link" href="#"> Informationen </a>
                                <ul className="sub-menu">
                                    <li><a className="nav-link" href="/globalis">Über uns</a></li>
                                    <li><a className="nav-link" href="/informationen/rkv">Reiseversicherungen</a>
                                    </li>
                                    <li><a className="nav-link"
                                           href="/informationen/reisebedingungen">Reisebedingungen</a></li>
                                    <li><a className="nav-link"
                                           href="/informationen/zahlungsweisen">Zahlungsweisen</a>
                                    </li>
                                    <li><HashLink
                                        className="nav-link"
                                        to="informationen/reisebedingungen#Storno">Stornogebühren</HashLink></li>
                                    <li><a className="nav-link" href="/informationen/faq">Oft gestellte Fragen</a>
                                    </li>
                                </ul>
                            </li>
                            <li className={`${location.pathname === "/kontakt" ? "current-menu-ancestor" : ""}`}>
                                <a className="nav-link" href="/kontakt">Kontakt</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header;