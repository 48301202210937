import React from 'react';
import HeaderSubPage from "./partial/HeaderSubPage";
const bgImage = process.env.PUBLIC_URL + "/images/headers/zahlungsbedingungen-wichayada-min.png";

const RKV = () => {
    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Zahlungsweisen'} subtitle={'Zahlungsweisen'} bgImage={bgImage}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <div className="pages_content">
                                    <h1 className="post_list_title">
                                        <span><strong>So bezahlen Sie Ihre Reise</strong></span></h1>

                                    <div>

                                        <p>Nach Eingang Ihrer Reiseanmeldung bei uns erhalten Sie umgehend eine
                                            verbindliche Reisebestätigung/Rechnung mit allen Einzelheiten, mit
                                            der der Reisevertrag gemäß unserer Reisebedingungen zustande
                                            kommt.</p><p>

                                        Bitte prüfen Sie nach Erhalt der Bestätigung die Namen nochmals auf ihre
                                        Richtigkeit und Vollständigkeit. Evtl. Änderungen teilen Sie uns bitte
                                        sofort mit. Sie erhalten dann von uns eine aktualisierte
                                        Rechnung/Bestätigung.</p><p>

                                        Nach Erhalt und Prüfung dieser Bestätigung bitten wir um eine
                                        Vorauszahlung in Höhe von 20% des Reisepreises. Der Restbetrag ist
                                        spätestens 30 Tage vor Reisebeginn zu bezahlen.</p><p>

                                        Die Fälligkeitstermine der Zahlungen sind auf der Rechnung ausgewiesen.
                                        Wir bitten Sie um fristgerechte Überweisung. Sie erhalten keine
                                        gesonderte Zahlungsaufforderung zum Restbetrag.</p><p>

                                        Falls Sie Ihre Zahlung per SEPA Lastschrift wünschen, ziehen wir die
                                        fälligen Beträge zu den angegebenen Terminen von Ihrem Konto ein.</p><p>

                                        Die kompletten Reiseunterlagen senden wir Ihnen ca. 14 Tage vor
                                        Reisebeginn nach erfolgter Zahlung zu.</p>
                                        <p>Anzahlung und Restzahlungen sind unabhängig von der Möglichkeit des
                                            Stornierens der Reise innerhalb der o.g. Fristen fällig. Sollten Sie
                                            zum Zeitpunkt der Stornierung bereits Zahlungen geleistet haben,
                                            werden wir Ihnen diese so schnell wie möglich zurücküberweisen.</p>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default RKV;