import HeaderSubPage from "./partial/HeaderSubPage";
import React from "react";

export default function NotFound() {
    const path = window.location.pathname

    if (path.includes("SchwaBo")) {
        window.location.href = "https://www.abo-bonus.globalis.de";
        return;
    }

    if (path.includes("Augsburger")) {
        window.location.href = "https://www.augsburg.globalis.de";
        return;
    }

    if (path.includes("AVG")) {
        window.location.href = "https://www.avg.globalis.de";
        return;
    }


    if (path.includes("AZ")) {
        window.location.href = "https://www.az.globalis.de";
        return;
    }

    if (path.includes("EsslingerZeitung")) {
        window.location.href = "https://www.ez-leserreisen.globalis.de";
        return;
    }

    if (path.includes("FAZ")) {
        window.location.href = "https://www.faz.globalis.de";
        return;
    }

    if (path.includes("fnp")) {
        window.location.href = "https://www.fnp.globalis.de";
        return;
    }

    if (path.includes("giesseneranzeiger")) {
        window.location.href = "https://www.ga.globalis.de";
        return;
    }

    if (path.includes("gaz")) {
        window.location.href = "https://www.gaz.globalis.de";
        return;
    }

    if (path.includes("Heilbronner_Stimme")) {
        window.location.href = "https://www.hn.globalis.de";
        return;
    }

    if (path.includes("hanau")) {
        window.location.href = "https://www.hu.globalis.de";
        return;
    }

    if (path.includes("kreisanzeiger")) {
        window.location.href = "https://www.kreisanzeiger.globalis.de";
        return;
    }

    if (path.includes("krzbb")) {
        window.location.href = "https://www.krzbb.globalis.de";
        return;
    }

    if (path.includes("Offenbach")) {
        window.location.href = "https://www.op.globalis.de";
        return;
    }

    if (path.includes("Rundschau")) {
        window.location.href = "https://www.rundschau.globalis.de";
        return;
    }

    if (path.includes("suedkurier")) {
        window.location.href = "https://www.sk-reisen.globalis.de";
        return;
    }

    if (path.includes("Stuttgarter_Zeitung")) {
        window.location.href = "https://www.stz.globalis.de";
        return;
    }

    if (path.includes("leserreisen")) {
        window.location.href = "https://www.sz.globalis.de";
        return;
    }

    if (path.includes("Tagesspiegel")) {
        window.location.href = "https://www.ts.globalis.de";
        return;
    }

    if (path.includes("usinger")) {
        window.location.href = "https://www.usingen.globalis.de";
        return;
    }

    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'404'}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">

                            <div className="page-wrap d-flex flex-row align-items-center">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-md-12 text-center">
                                            <h1 className="display-1 d-block">404</h1>
                                            <h1 className="mb-4">Sorry. Die angeforderte Seite / URL existiert
                                                nicht.</h1>
                                            <a href="/" className="btn btn-primary">zu Hauptseite</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}