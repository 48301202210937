import {fetchDiscountOffer, formatCurrency} from "../utils/utils";
import React, {useEffect, useState} from "react";
import Image3 from "../../images/home/bg-pallarax.jpg";

const Discount = () => {
    const [discount, setDiscount] = useState([]);

    useEffect(() => {
        fetchDiscountOffer().then((data) => {
            setDiscount(data);
        });
    }, []);
    return (
        <div className="bg__shadow padding-top-6x padding-bottom-6x section-background"
             style={{
                 backgroundImage: 'url(' + Image3 + ')'
             }}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8">
                        {discount &&  discount.length > 0 ? (
                            <div className="discounts-tour">
                                <h3 className="discounts-title">
                                    {discount.name}
                                    {discount.description}
                                    <span> {formatCurrency(discount.amount)} mit dem Rabatt-Code: {discount.code}</span>
                                </h3>
                                <span className="line"></span>
                                <p>Die Plätze sind begrenzt! {discount?.diff?.y}</p>
                                <div className="row centered text-center"
                                     data-y={discount?.y}
                                     data-m={discount?.m}
                                     data-d={discount?.d}
                                     id="myCounter"></div>
                                <div className="col-sm-12 text-center padding-top-2x">
                                    <a href={`/reise/${encodeURIComponent(discount.Kurztitel)}?id=${discount.ID}`}
                                       className="icon-btn"><i
                                        className="flaticon-airplane-4"></i> Jetzt buchen </a>
                                </div>
                            </div>) : (
                            <div className="discounts-tour">
                                <h3 className="discounts-title">
                                    Unsere Aktuelle Bestseller Reisen <br/>
                                    auf einen Blick
                                </h3>
                                <span className="line"></span>
                                <p>Die beliebtesten Reise-Bestseller!</p>
                                <div className="col-sm-12 text-center padding-top-2x">
                                    <a href={`/reisefinder`}
                                       className="icon-btn">
                                        {/*<i className="flaticon-airplane-4"></i>*/}
                                        Jetzt entdecken </a>
                                </div>
                            </div>
                        )}

                    </div>
                    <div className="col-sm-2"></div>
                </div>
            </div>
        </div>
    )
}

export default Discount;