import React, {useEffect, useState} from 'react';
import axiosConfig from "../utils/axiosConfig";

const Partners = () => {
    const [partners, setPartners] = useState([]);

    // todo: change partner href
    const fetchPartner = async () => {
        try {
            const response = await axiosConfig.get(`/partners`);
            setPartners(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchPartner();
    }, []);
    return (
        <div className="wrapper-footer wrapper-footer-newsletter partners d-none">
            <div className="main-top-footer">
                <div className="container">
                    <div className="row">
                        <aside className="col-sm-3 widget_text"><h3 className="widget-title">Unsere exklusive
                            Leserreisen Partner:</h3>
                            <div className="textwidget">
                                <ul className="menu list-arrow">
                                    {partners?.slice(0, 6).map((p, index) => (
                                        <li key={index}><a target="_blank" href={'https://' + p.domain.replace("globalis", 'banktouristik')}>{p.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </aside>
                        <aside className="col-sm-3 widget_text"><h3 className="widget-title">&nbsp;</h3>
                            <div className="textwidget">
                                <ul className="menu list-arrow">
                                    {partners.slice(6, 12).map((p, x) => (
                                        <li key={x}><a target="_blank" href={'https://' + p.domain.replace("globalis", 'banktouristik')}>{p.name}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </aside>
                        <aside className="col-sm-3 widget_text"><h3 className="widget-title">&nbsp;</h3>
                            <div className="textwidget">
                                <ul className="menu list-arrow">
                                    {partners.slice(12, 18).map((p, i) => (
                                        <li key={i}><a target="_blank" href={'https://' + p.domain.replace("globalis", 'banktouristik')}>{p.name}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </aside>
                        <aside className="col-sm-3 widget_text"><h3 className="widget-title">&nbsp;</h3>
                            <div className="textwidget">
                                <ul className="menu list-arrow">
                                    {partners.slice(18, 24).map((p, i) => (
                                        <li key={i}><a target="_blank" href={'https://' + p.domain.replace("globalis", 'banktouristik')}>{p.name}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partners;