import React from 'react';
import {HashLink} from "react-router-hash-link";
import Copyright from "./Copyright";
import Partners from "./Partners";
import {FavModal, SearchModal} from "./Modals";
import Cookie from "../CookieConsent";
import {formatPhoneNumber, getPhoneNumber} from "../utils/utils";

const Footer = ({partner}) => {
    return (
        <>
            <div className="wrapper-footer wrapper-footer-newsletter">
                <div className="main-top-footer">
                    <div className="container">
                        <div className="row">
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">Kontakt</h3>
                                <div className="textwidget">
                                    <div className="footer-info">
                                        <ul className="contact-info">
                                            <li> GLOBALIS Erlebnisreisen GmbH</li>
                                            <li><i className="fa fa-map-marker fa-fw"></i>Uferstraße 24</li>
                                            <li><i className="fa fa-map-marker fa-fw"></i>61137 Schöneck</li>
                                            <li><i className="fa fa-phone fa-fw"></i>
                                                <a href={`tel:${formatPhoneNumber(partner.phone)}`}>{getPhoneNumber(partner.phone)}</a>
                                            </li>
                                            <li>
                                            <i className="fa fa-envelope fa-fw"></i><a
                                                href={`mailto:${partner.email}`}>
                                                {partner.email}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">Rechtliches</h3>
                                <div className="textwidget">
                                    <ul className="menu list-arrow">
                                        <li><a href="/datenschutz">Datenschutz</a></li>
                                        <li><a href="/impressum">Impressum</a></li>
                                    </ul>
                                </div>
                            </aside>
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">Reisebedingungen</h3>
                                <div className="textwidget">
                                    <ul className="menu list-arrow">
                                        <li><a href="/informationen/rkv">Reiseversicherungen</a></li>
                                        <li><a href="/informationen/reisebedingungen">Reisebedingungen</a></li>
                                        <li><a href="/informationen/zahlungsweisen">Zahlungsweisen</a></li>
                                        <li><HashLink
                                            to="/informationen/reisebedingungen#Storno">Stornogebühren</HashLink>
                                        </li>
                                        <li><a href="/informationen/faq">Oft gestellte Fragen</a></li>

                                    </ul>
                                </div>
                            </aside>
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">GLOBALIS</h3>
                                <div className="textwidget">
                                    <ul className="menu list-arrow">
                                        <li><a href="/globalis">Die GLOBALIS Philosophie</a></li>
                                        <li><a href="/referenzen">Referenzen</a></li>
                                        <li><a href="/karriere">Karriere</a></li>
                                        <li><a href="/kontakt">Kontakt</a></li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
                <Copyright partner={partner}></Copyright>
            </div>
            <Partners></Partners>
            <SearchModal></SearchModal>
            <FavModal></FavModal>
            <Cookie  partner={partner}></Cookie>
        </>
    )
}

export default Footer;