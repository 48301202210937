import * as React from "react";
import {useFormContext} from "react-hook-form";
import ScrollToTop, {formatCurrency, moka} from "../../utils/utils";

export default function Step3({availableAccommodations}) {
    const methods = useFormContext();
    return (
        <>
            <ScrollToTop></ScrollToTop>
            <div className="">
                <p><strong>WICHTIG! </strong>Für die Ausstellung der Reisedokumente
                    wie
                    Tickets
                    etc. müssen die o.g. Daten mit denen Ihrer Ausweis­dokumente
                    übereinstimmen,
                    da wir sonst die verursachten Mehrkosten weiterbelasten müssen
                    und
                    es zu
                    erheblichen Problemen bis hin zur Nichtbeförderung kommen kann.
                </p>
                {moka(methods.getValues("accommodations"))?.map((rooms, roomIndex) => (
                    <div className="card">
                        <div className="card-header">
                            <h3>{rooms.accommodation?.bez_lang} für {rooms.accommodation?.belegung} Personen</h3>
                        </div>
                        <div className="card-body">
                            <input
                                type="hidden"
                                value={rooms.accommodation?.vuId}
                                {...methods.register(`occupations[${roomIndex}][vuId]`, {required: true})}/>
                            <input
                                type="hidden"
                                value={rooms.accommodation?.suId}
                                {...methods.register(`occupations[${roomIndex}][suId]`, {required: true})}/>
                            <input
                                type="hidden"
                                value={rooms.accommodation?.bez_lang}
                                {...methods.register(`occupations[${roomIndex}][bez_lang]`, {required: true})}/>
                            <input
                                type="hidden"
                                value={rooms.accommodation?.belegung}
                                {...methods.register(`occupations[${roomIndex}][belegung]`, {required: true})}/>
                            <input
                                type="hidden"
                                value={rooms.accommodation?.ppp}
                                {...methods.register(`occupations[${roomIndex}][ppp]`, {required: true})}/>
                            {rooms.occupations?.map((occupation, occupationIndex) => (
                                <div className="card">
                                    <div className="card-header  sub-header">
                                        <h3>Teilnehmer {occupationIndex + 1}</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <select
                                                        className="form-control form-control-lg"
                                                        name={`occupations[${roomIndex}][participants][${occupationIndex}][gender]`}
                                                        {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}][gender]`, {required: true})}>
                                                        <option value="">Bitte wählen:</option>
                                                        <option value="2">Frau</option>
                                                        <option value="1">Herr</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Vorname"
                                                        {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}][firstname]`, {required: true})}/>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Nachname"
                                                        {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}][lastname]`, {required: true})}/>
                                                </div>
                                            </div>
                                        </div>

                                        {methods.getValues("services")?.length > 0 &&
                                            <>
                                                <h2 className="mb-4 mt-4">Zusatzleistungen</h2>
                                                {methods.getValues("services")?.map((service, x) => (
                                                    <div className="">
                                                        <input
                                                            type="hidden"
                                                            {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}].services[${x}].vk_preis`, {required: true})}
                                                            value={service.vk_preis}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}].services[${x}].bezeichnung`, {required: true})}
                                                            value={service.bezeichnung}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}].services[${x}].id`, {required: true})}
                                                            value={service.id}
                                                        />
                                                        <div className="mb-3">
                                                            <input className="form-check-input"
                                                                   type="checkbox"
                                                                   defaultChecked={methods.getValues("occupations")[roomIndex].participants[occupationIndex].services[x].value ?? service.value}
                                                                   {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}].services[${x}].value`, {required: false})}/>
                                                            <label className="form-check-label">
                                                                {service.bezeichnung}
                                                            </label>
                                                            <label className="form-check-label float-end">
                                                                {formatCurrency(service.vk_preis)} p.P.
                                                            </label>

                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        }

                                        {methods.formState.errors['occupations'] !== undefined && methods.formState.errors['occupations'][roomIndex] && methods.formState.errors['occupations'][roomIndex][occupationIndex] &&
                                            <p className="error">Bitte
                                                Daten für teilnehmer {occupationIndex + 1} vollständigen</p>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
