import * as React from "react";
import {useFormContext} from "react-hook-form";
import ScrollToTop from "../../utils/utils";

export default function Step2({
                                  includedServices,
                                  availableAccommodations,
                                  transactionDetails,
                                  grundpreis,
                                  advancedServices
                              }) {
    const methods = useFormContext();
    return (
        <>
            <ScrollToTop></ScrollToTop>
            <div className="card">
                <div className="card-header">
                    <h3>Unterkünfte Auswahl</h3>
                </div>
                <div className="card-body">
                    <div className="">
                        <div className="travel_tour-shipping-fields">
                            <input
                                type="hidden"
                                {...methods.register(`transaction.tripId`, {required: true})}
                                value={transactionDetails.REISE_ID}
                            />
                            <input
                                type="hidden"
                                {...methods.register(`transaction.tripTitle`, {required: true})}
                                value={transactionDetails.Kurztitel}
                            />
                            <input
                                type="hidden"
                                {...methods.register(`transaction.id`, {required: true})}
                                value={transactionDetails.VORGANG_ID}
                            />
                            <input
                                type="hidden"
                                {...methods.register(`transaction.no`, {required: true})}
                                value={transactionDetails.VORGANGNR}
                            />
                            <input
                                type="hidden"
                                {...methods.register(`transaction.agencyNo`, {required: true})}
                                value={transactionDetails.AGENTURNUMMER}
                            />
                            <table
                                className="table table-responsive borderless table-striped">
                                <tbody>
                                {availableAccommodations.map((post, index) => (
                                    <tr>
                                        <td>
                                            <input
                                                type="hidden"
                                                {...methods.register(`accommodations[${index}].ppp`, {required: true})}
                                                value={Number(grundpreis.Grundpreis) + Number(post.U_ZUSCHLAG)}
                                            />
                                            <input
                                                type="hidden"
                                                {...methods.register(`accommodations[${index}].bez_lang`, {required: true})}
                                                value={post.BEZ_LANG}
                                            />
                                            <input
                                                type="hidden"
                                                {...methods.register(`accommodations[${index}].vuId`, {required: true})}
                                                value={post.V_UNTERKUENFTE_ID}
                                            />
                                            <input
                                                type="hidden"
                                                {...methods.register(`accommodations[${index}].suId`, {required: true})}
                                                value={post.STAMM_UNTERKUENFTE_ID}
                                            />
                                            <input
                                                type="hidden"
                                                {...methods.register(`accommodations[${index}].belegung`, {required: true})}
                                                value={post.BELEGUNG}
                                            />
                                            <select
                                                className="form-select form-select-lg mb-5"
                                                defaultValue=""
                                                onClick={() => {
                                                    methods.reset(formValues => ({
                                                        ...formValues,
                                                        occupations: [],
                                                    }))
                                                }}
                                                {...methods.register(`accommodations[${index}].value`, {required: true})}>
                                                <option value="">Bitte wählen</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                            {methods.formState.errors['accommodations'] !== undefined && methods.formState.errors['accommodations'][index] &&
                                                <p className="error">Bitte Unterkünft
                                                    auswählen</p>}
                                        </td>
                                        <td>{post.BEZ_LANG} für {post.BELEGUNG} {post.BELEGUNG === 1 ? 'Person' : 'Personen'}</td>
                                        <td>{Number(grundpreis.Grundpreis) + Number(post.U_ZUSCHLAG)},- € p.P.</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {advancedServices.length > 0 &&
                <div className="card d-none">
                    <div className="card-header">
                        <h3>Leistungen Auswahl:</h3>
                    </div>
                    <div className="card-body">
                        <div className="">
                            <div className="travel_tour-shipping-fields">

                                <table className="table table-responsive borderless table-striped">
                                    <tbody>
                                    {advancedServices.map((service, index) => (
                                        <tr>
                                            <td>
                                                <div className="">
                                                    <input
                                                        type="hidden"
                                                        {...methods.register(`services[${index}].vk_preis`, {required: true})}
                                                        value={service.VK_PREIS}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        {...methods.register(`services[${index}].bezeichnung`, {required: true})}
                                                        value={service.BEZEICHNUNG}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        {...methods.register(`services[${index}].id`, {required: true})}
                                                        value={service.ID}
                                                    />
                                                    <div className="mb-3">
                                                        <input className="form-check-input"
                                                               type="checkbox"
                                                               {...methods.register(`services.${index}.value`, {required: true})}/>
                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                            {service.BEZEICHNUNG}
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{service.VK_PREIS},- € p.P.</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
        ;
}
