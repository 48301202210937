import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Fetching from "./fetching";
import TripCard from "./partial/TripCard";
import HeaderSubPage from "./partial/HeaderSubPage";
import axiosConfig from "./utils/axiosConfig";
import InfiniteScroll from "react-infinite-scroller";
import {allTripTypes} from "./utils/utils";

const Type = () => {

    const params = useParams();
    const type = params["type"];
    const currentType = allTripTypes.find((t) => t.title === type);

    const [trips, setTrips] = useState([]);
    const [nextPage, setNextPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [fetching, setFetching] = useState(false);


    const fetchTrips = useCallback(async () => {
        if (fetching) return;

        setFetching(true);
        try {
            const response = await axiosConfig.get(getEndPoint());
            //console.log(response.data)
            setTrips([...trips, ...response.data.data]);

            setNextPage(response.data.current_page + 1)
            setLastPage(response.data.last_page)
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setFetching(false);
        }
    }, [nextPage, lastPage, fetching]);

    const getEndPoint = () => {
        let types = "";
        switch (type) {
            case "Flugreisen":
                types = "Fluganreise"
                return `/agency/${process.env.REACT_APP_AGENCY_NO}/trips?type=${types}&page=${nextPage}`

            case "Flusskreuzfahrten": // todo
                types = "Flussreise"
                return `/agency/${process.env.REACT_APP_AGENCY_NO}/trips?type=${types}&page=${nextPage}`

            case "Hochseekreuzfahrten":
                types = "Hochsee-Kreuzfahrt"
                return `/agency/${process.env.REACT_APP_AGENCY_NO}/trips?type=${types}&page=${nextPage}`

            case "Kultur- und Eventreisen":
                types = "Standortreise mit Kultur-Event,Rundreise mit Kultur-Event"
                return `/agency/${process.env.REACT_APP_AGENCY_NO}/trips?type=${types}&page=${nextPage}`

            case "Wellness und Vitalreisen":
                types = "Wellness- und Vital-Reise"
                return `/agency/${process.env.REACT_APP_AGENCY_NO}/trips?type=${types}&page=${nextPage}`

            case "Busreisen":
                types = "Busanreise"
                return `/agency/${process.env.REACT_APP_AGENCY_NO}/trips?type=${types}&page=${nextPage}`

            case "Reisen mit Eigenanreise":
                types = "Eigenanreise"
                return `/agency/${process.env.REACT_APP_AGENCY_NO}/trips?type=${types}&page=${nextPage}`

            default:
                return `/agency/${process.env.REACT_APP_AGENCY_NO}/trips?&page=${nextPage}`
        }
    }

    const hasMoreItems = nextPage <= lastPage;

    // Call fetchData on component mount
    useEffect(() => {
    }, []);
    return (
        <div className="travel_tour">
            <div className="site wrapper-content">
                <HeaderSubPage title={type} subtitle={'Reisearten'} bgImage={currentType.bgImage}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-6">
                                <div className="ce-bodytext">
                                    <h1 className="mb-3"> Unsere aktuellen {type} </h1>
                                    <p className="mb-5">{currentType.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <InfiniteScroll
                                    pageStart={nextPage}
                                    loadMore={fetchTrips}
                                    hasMore={hasMoreItems}
                                    loader={<Fetching isFetching={true}></Fetching>}
                                >
                                    <ul className="tours products wrapper-tours-slider equal">
                                        {trips.map((post, index) => (
                                            <TripCard trip={post} cnt={4} key={index}></TripCard>
                                        ))}
                                    </ul>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Type;