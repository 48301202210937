import React from 'react';
import HeaderSubPage from "./partial/HeaderSubPage";
import {formatPhoneNumber, getPhoneNumber} from "./utils/utils";

const bgImage = process.env.PUBLIC_URL + "/images/headers/faq-header.png"

const FAQ = ({partner}) => {
    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Häufig gestellte Fragen'} subtitle={'Häufig gestellte Fragen'} bgImage={bgImage}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-12 ">
                                <h1 className="post_list_title">
                                    <strong>Wir helfen Ihnen gerne!</strong>
                                </h1>

                                <h3>Hier finden Sie schnell Antworten zu vielen Bereichen von GLOBALIS.
                                    Klicken Sie
                                    einfach auf die Frage - die Antwort wird Ihnen dann angezeigt.
                                </h3>
                            </div>
                        </div>
                        <div className="row">
                            <h2 className="post_list_title  pt-5">
                                <strong>Häufig gestellte Fragen (FAQ)</strong>
                            </h2>

                            <div className="accordion  pt-5" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                aria-expanded="false" aria-controls="collapseOne">
                                            1. Wie kann ich meine Reise buchen?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show"
                                         aria-labelledby="headingOne"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Sie können Ihre Reise
                                                einfach hier online buchen: Klicken Sie bei Ihrer
                                                Wunschreise auf den Reiter "JETZT BUCHEN". Sie sehen
                                                dann alle Abflughäfen sowie die verfügbaren Termine.
                                                Klicken Sie auf "REISE BUCHEN" und füllen Sie das
                                                Formular aus.<br/>
                                                <br/>
                                                Sie können auch telefonisch bei unserer Hotline
                                                buchen. Unsere freundlichen Service-Mitarbeiter sind
                                                persönlich für Sie da.<br/>
                                                <br/>
                                                Natürlich können Sie uns Ihren Buchungswunsch auch
                                                per <strong>Post, Fax oder e-Mail</strong> senden.
                                                Die Kontaktdaten finden Sie untenstehend.<br/>
                                                <br/>
                                                <strong>Zur Buchung benötigen wir:</strong><br/>
                                                <br/>
                                                • Angaben zum Reiseziel, Reisetermin, Abflughafen,
                                                gewünschte Unterkunft sowie ggf. die
                                                Wunschleistungen zu Ihrer Reise<br/>
                                                <br/>
                                                • Vor- und Nachnamen sowie das Geburtsdatum aller
                                                Reisenden<br/>
                                                <br/>
                                                • Postanschrift und Telefonnummer (für evtl.
                                                Rückfragen) vom Rechnungsempfänger<br/>
                                                <br/>
                                                • falls vorhanden: e-Mail-Adresse vom
                                                Rechnungsempfänger
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                            2. Wie erfahre ich, ob meine Reise verbindlich gebucht ist?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                         aria-labelledby="headingTwo"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Nach Abschluss Ihrer Buchung erhalten Sie umgehend eine
                                                E-Mail mit der Bestätigung, dass Ihre Reise für Sie in
                                                unserem Reservierungssystem angelegt worden ist. Bitte lesen
                                                diese E-Mail aufmerksam durch. <br/>
                                                <br/>
                                                Innerhalb von 2-3 Tagen erhalten Sie außerdem eine
                                                schriftliche Reisebestätigung/Rechnung, mit der Ihre Buchung
                                                verbindlich wird.<br/>
                                                <br/>
                                                Wenn Sie zwei Stunden nach Ihrer Buchung noch keine
                                                Bestätigungs-E-Mail erhalten haben, überprüfen Sie bitte
                                                zuerst, ob in Ihrem E-Mail-Programm eine Anti-Spam-Funktion
                                                eingeschaltet ist, bzw. die Bestätigungs-E-Mail als Spam
                                                oder Junk in einen anderen Ordner verschoben wurde.<br/>
                                                <br/>
                                                Falls Sie keine e-Mail erhalten haben, nehmen Sie bitte
                                                Kontakt mit uns auf.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">
                                            3. Ticketloses Reisen und Fliegen
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse"
                                         aria-labelledby="headingThree"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Sie erhalten keine separaten Reisegutscheine oder Tickets.
                                                Ihre laut Rechnung/Bestätigung gebuchten Leistungen geben
                                                wir an die Fluggesellschaft sowie unsere Partner vor Ort
                                                weiter. Bitte vergleichen Sie die Angaben auf Ihrer
                                                Rechnung/Bestätigung mit Ihrer Buchung, und überprüfen Sie
                                                diese auf Ihre Richtigkeit.<br/>
                                                <br/>
                                                Für Ihren Flug wird das sogenannte "Ticketlose fliegen"
                                                angewandt. Im weltweiten Flugverkehr werden keine
                                                traditionellen Flugtickets mehr gedruckt. Für den Check-In
                                                benötigen Sie lediglich Ihren gültigen Personalausweis oder
                                                Reisepass sowie die Reiseunterlagen. Ihre Namen sind am
                                                Check-In gespeichert. Bitte beachten Sie, dass die
                                                Reservierungen personengebunden sind und die bei Buchung
                                                angegebenen Namen mit dem Lichtbildausweis übereinstimmen
                                                müssen.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading4">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse4" aria-expanded="false"
                                                aria-controls="collapse4">
                                            4. Wie bezahle ich meine Reise?
                                        </button>
                                    </h2>
                                    <div id="collapse4" className="accordion-collapse collapse"
                                         aria-labelledby="heading4"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Nach Eingang Ihrer Reiseanmeldung bei uns erhalten Sie
                                                umgehend eine verbindliche Reisebestätigung/Rechnung mit
                                                allen Einzelheiten, mit der der Reisevertrag gemäß unserer
                                                Reisebedingungen zustande kommt.<br/>
                                                <br/>
                                                Bitte prüfen Sie nach Erhalt der Bestätigung die Namen
                                                nochmals auf ihre Richtigkeit und Vollständigkeit. Evtl.
                                                Änderungen teilen Sie uns bitte sofort mit. Sie erhalten
                                                dann von uns eine aktualisierte Rechnung/Bestätigung.<br/>
                                                <br/>
                                                Nach Erhalt und Prüfung dieser Bestätigung bitten wir um
                                                eine Vorauszahlung in Höhe von 20% des Reisepreises. Der
                                                Restbetrag ist spätestens 30 Tage vor Reisebeginn zu
                                                bezahlen.<br/>
                                                <br/>
                                                Die Fälligkeitstermine der Zahlungen sind auf der Rechnung
                                                ausgewiesen. Wir bitten Sie um fristgerechte Überweisung.
                                                Sie erhalten keine gesonderte Zahlungsaufforderung zum
                                                Restbetrag.<br/>
                                                <br/>
                                                Falls Sie Ihre Zahlung per SEPA Lastschrift wünschen, ziehen
                                                wir die fälligen Beträge zu den angegebenen Terminen von
                                                Ihrem Konto ein.<br/>
                                                <br/>
                                                Kreditkartenzahlung ist möglich, bitte sprechen Sie uns
                                                darauf an.<br/>
                                                <br/>
                                                Die kompletten Reiseunterlagen senden wir Ihnen ca. 14 Tage
                                                vor Reisebeginn nach erfolgter Zahlung zu.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading5">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse5" aria-expanded="false"
                                                aria-controls="collapse5">
                                            5. Wo finde ich die Einreisebestimmungen?
                                        </button>
                                    </h2>
                                    <div id="collapse5" className="accordion-collapse collapse"
                                         aria-labelledby="heading5"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Reisegäste mit deutscher
                                                Staatsangehörigkeit finden die Einreisebestimmungen für
                                                ihr Reiseziel bei der Reisebeschreibung unter dem Reiter
                                                WICHTIGE REISEINFOS. <br/>
                                                <br/>
                                                Ausführliche Informationen erhalten Sie außerdem beim <a
                                                href="http://www.auswaertiges-amt.de/DE/Laenderinformationen/SicherheitshinweiseA-Z-Laenderauswahlseite_node.html"
                                                target="_blank">Auswärtigen Amt</a>.<br/>
                                                <br/>
                                                Reisegäste mit nicht deutscher Staatsangehörigkeit
                                                erhalten von uns auf Anfrage gerne die für sie aktuell
                                                gültigen Bestimmungen für das gewünschte Reiseland.<br/>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading6">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse6" aria-expanded="false"
                                                aria-controls="collapse6">
                                            6. Wie erhalte ich den Reisesicherungsschein?
                                        </button>
                                    </h2>
                                    <div id="collapse6" className="accordion-collapse collapse"
                                         aria-labelledby="heading6"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Der Reisesicherungsschein ist
                                                ein im deutschen Reiserecht bei Pauschalreisen
                                                vorgeschriebenes Versicherungsinstrument, mit welchem
                                                Anzahlungen und Restzahlungen von Reiseleistungen an
                                                Reiseveranstalter abgesichert werden müssen. <br/>
                                                <br/>
                                                Reisesicherungsscheine bei jeder Pauschalreise
                                                mitzuliefern ist Pflicht und wird bei uns deshalb auch
                                                nicht gesondert in den eingeschlossenen Leistungen
                                                aufgeführt.<br/>
                                                <br/>
                                                Der Reisesicherungsschein bietet Gewähr dafür, dass im
                                                Falle der Zahlungsunfähigkeit oder des Konkurses des
                                                Reiseveranstalters, der gezahlte Reisepreis, die
                                                geleistete Anzahlung und die notwendigen Aufwendungen
                                                (auch Mehraufwendungen) für die Rückreise erstattet
                                                werden.<br/>
                                                <br/>
                                                Den Sicherungsschein für Ihre GLOBALIS-Reise erhalten
                                                Sie mit der Rechnung/Reisebestätigung.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading7">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse7" aria-expanded="false"
                                                aria-controls="collapse7">
                                            7. Sind Reiseversicherungen im Preis enthalten?
                                        </button>
                                    </h2>
                                    <div id="collapse7" className="accordion-collapse collapse"
                                         aria-labelledby="heading7"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Viele unserer Reisegäste haben
                                                bereits einen Reiseversicherungsschutz in Form von
                                                Jahresversicherungen und ähnlichem. Sofern nicht
                                                gesondert bestätigt, sind deshalb Reiseversicherungen in
                                                den Leistungen nicht obligatorisch eingeschlossen. <br/>
                                                <br/>
                                                Wir empfehlen Ihnen, einen entsprechenden
                                                Versicherungsschutz abzuschließen.<br/>
                                                <br/>
                                                Informieren Sie sich vorab über die verschiedenen
                                                Reiseschutz-Möglichkeiten unseres Partners ALLIANZ
                                                TRAVEL, lassen Sie sich die Preise berechnen und
                                                natürlich können Sie den von Ihnen gewünschten
                                                Reiseschutz auch online direkt bei ALLIANZ TRAVEL
                                                buchen.<br/>
                                                <br/>
                                                <strong>Informationen und Preise zum <a
                                                    href="https://www.magroup-online.com/WL/HBC/DE/de?agency=GLO144"
                                                    target="_blank" rel="noopener noreferrer">REISESCHUTZ
                                                    der ALLIANZ TRAVEL finden Sie unter diesem
                                                    Link</a>.</strong><br/>
                                                <br/>
                                                Natürlich können Sie Ihren Versicherungsschutz auch bei
                                                jeder anderer Versicherung Ihrer Wahl abschließen.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading8">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse8" aria-expanded="false"
                                                aria-controls="collapse8">
                                            8. Wann erhalte ich meine Reiseunterlagen?
                                        </button>
                                    </h2>
                                    <div id="collapse8" className="accordion-collapse collapse"
                                         aria-labelledby="heading8"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Die kompletten Reiseunterlagen
                                                senden wir Ihnen ca. 14 Tage vor Reisebeginn nach
                                                erfolgter Zahlung zu.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading9">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse9" aria-expanded="false"
                                                aria-controls="collapse9">
                                            9. Wo finde ich die genauen Flugzeiten?
                                        </button>
                                    </h2>
                                    <div id="collapse9" className="accordion-collapse collapse"
                                         aria-labelledby="heading9"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Die genauen Flugzeiten und Flugnummern erhalten Sie zusammen
                                                mit Ihren Reiseunterlagen.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading10">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse10" aria-expanded="false"
                                                aria-controls="collapse10">
                                            10. Gibt es spezielle Impf- und Gesundheitsvorschriften?
                                        </button>
                                    </h2>
                                    <div id="collapse10" className="accordion-collapse collapse"
                                         aria-labelledby="heading10"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Die Impfbestimmungen für Ihr
                                                Reiseziel finden Sie bei der Reisebeschreibung unter dem
                                                Reiter WICHTIGE REISEINFOS.<br/>
                                                <br/>
                                                Bitte informieren Sie sich darüber hinaus rechtzeitig
                                                über Infektions- und Impfschutz sowie andere
                                                Prophylaxemaßnahmen; ggf. sollte ärztlicher Rat zu
                                                Thrombose- und anderen Gesundheitsrisiken eingeholt
                                                werden. Kontaktieren Sie am besten Ihren Hausarzt und
                                                besprechen Sie mit ihm Ihren eventuellen persönlichen
                                                Impfplan. Empfohlen werden folgende Impfungen: Tetanus,
                                                Diphtherie, Polio, Hepatitis A. <br/>
                                                <br/>
                                                Es ist auch ratsam, ständig benötigte Medikamente in
                                                Ihre Reiseapotheke einzupacken. Weiterhin verweisen wir
                                                auf allgemeine Informationen, insbesondere bei den
                                                Gesundheitsämtern, reisemedizinisch erfahrenen Ärzten,
                                                Tropenmedizinern, reisemedizinischen
                                                Informationsdiensten oder der Bundeszentrale für
                                                gesundheitliche Aufklärung.<br/>
                                                <br/>
                                                Ausführliche Informationen erhalten Sie außerdem beim <a
                                                href="http://www.auswaertiges-amt.de/DE/Laenderinformationen/SicherheitshinweiseA-Z-Laenderauswahlseite_node.html"
                                                target="_blank">Auswärtigen Amt</a>.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading11">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse11" aria-expanded="false"
                                                aria-controls="collapse11">
                                            11. Wie kann ich meine Reise gegebenenfalls stornieren?
                                        </button>
                                    </h2>
                                    <div id="collapse11" className="accordion-collapse collapse"
                                         aria-labelledby="heading11"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Sie können bis Reisebeginn
                                                jederzeit durch Erklärung gegenüber uns, die schriftlich
                                                erfolgen soll, vom Reisevertrag zurücktreten. Maßgeblich
                                                ist der Eingang bei uns.<br/>
                                                <br/>
                                                In jedem Fall des Rücktritts durch den Reisenden stehen
                                                uns unter Berücksichtigung gewöhnlich ersparter
                                                Aufwendungen und die gewöhnlich mögliche anderweitige
                                                Verwendung der Reiseleistungen folgende pauschale
                                                Entschädigungen vom Reisepreis pro Person zu:<br/>
                                                <br/>
                                                a) Flugpauschalreisen mit Linien- oder Charterflug sowie
                                                Reisen, die nicht unter die nachfolgenden Ziffern b) und
                                                c) fallen<br/>
                                                bis zum 31. Tag vor Reiseantritt 20 %<br/>
                                                ab dem 30. Tag vor Reiseantritt 35 % <br/>
                                                ab dem 22. Tag vor Reiseantritt 45 %<br/>
                                                ab dem 15. Tag vor Reiseantritt 60 %<br/>
                                                ab dem 8. Tag vor Reiseantritt 80 %<br/>
                                                ab dem 2. Tag vor Reiseantritt bis zum Tag des
                                                Reiseantritts oder bei Nichtantritt der Reise 90 % des
                                                Reisepreises;<br/>
                                                <br/>
                                                b) Bus- und Bahnanreisen sowie Reisen mit Besuch einer
                                                Veranstaltung (Konzert, Theater oder Sport)<br/>
                                                bis zum 60. Tag vor Reiseantritt 20 %<br/>
                                                ab dem 59. Tag vor Reiseantritt 40 % <br/>
                                                ab dem 15. Tag vor Reiseantritt 80 %<br/>
                                                ab dem 2. Tag vor Reiseantritt bis zum Tag des
                                                Reiseantritts oder bei Nichtantritt der Reise 90 % des
                                                Reisepreises;<br/>
                                                <br/>
                                                c) Flusskreuzfahrten <br/>
                                                bis zum 31. Tag vor Reiseantritt 20 %<br/>
                                                ab dem 30. Tag vor Reiseantritt 45 %<br/>
                                                ab dem 22. Tag vor Reiseantritt 60 %<br/>
                                                ab dem 15. Tag vor Reiseantritt 85 %<br/>
                                                ab dem 2. Tag vor Reiseantritt bis zum Tag des
                                                Reiseantritts oder bei Nichtantritt der Reise 95 % des
                                                Reisepreises;<br/>
                                                <br/>
                                                d) Hochseereisen <br/>
                                                bis zum 90. Tag vor Reiseantritt 20 %<br/>
                                                ab dem 89. Tag vor Reiseantritt 35 %<br/>
                                                ab dem 59. Tag vor Reiseantritt 50 %<br/>
                                                ab dem 29. Tag vor Reiseantritt 75 %<br/>
                                                ab dem 9. Tag vor Reiseantritt 85 %<br/>
                                                ab dem 2. Tag vor Reiseantritt bis zum Tag des
                                                Reiseantritts oder bei Nichtantritt der Reise 95 % des
                                                Reisepreises.<br/>
                                                <br/>
                                                <br/>
                                                Sie erhalten dann von uns eine entsprechende
                                                Stornorechnung, welche Sie bei Ihrem
                                                Reiserücktrittskosten-Versicherer (sofern Sie eine
                                                entsprechende Versicherung abgeschlossen haben)
                                                einreichen können.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading12">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse12" aria-expanded="false"
                                                aria-controls="collapse12">
                                            12. Was bedeutet Rail&Fly - Zug zum Flug?
                                        </button>
                                    </h2>
                                    <div id="collapse12" className="accordion-collapse collapse"
                                         aria-labelledby="heading12"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Reisen Sie stressfrei mit der
                                                Deutschen Bahn zum Flughafen. Rail&amp;Fly beinhaltet
                                                die Bahnanreise von/zu jedem der über 5.600 DB-Bahnhöfe
                                                sowie von/zu jedem deutschen Flughafen sowie den
                                                Flughäfen Basel und Salzburg, auf dem gesamten
                                                DB-Streckennetz. <br/>
                                                <br/>
                                                Die Rail&amp;Fly-Fahrkarten gelten am Tag vor dem
                                                Abflugtag, am Abflugtag selbst, am Tag der Rückkehr und
                                                dem Tag danach. Alle weiteren Informationen zu
                                                Rail&amp;Fly erhalten Sie mit Ihren Reiseunterlagen.
                                                Rail&amp;Fly kann nicht nachträglich an den
                                                Verkaufsstellen der DB erworben werden.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading13">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse13" aria-expanded="false"
                                                aria-controls="collapse13">
                                            13. Was darf in das Handgepäck?
                                        </button>
                                    </h2>
                                    <div id="collapse13" className="accordion-collapse collapse"
                                         aria-labelledby="heading13"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Unter Berücksichtigung Ihrer
                                                Sicherheit sowie der Bequemlichkeit ist nur ein
                                                Handgepäckstück pro Person gestattet. Das Handgepäck
                                                darf maximal ein Gewicht von 8 kg aufweisen, und die
                                                Abmessungen dürfen die Maße 25 cm x 45 cm x 56 cm nicht
                                                überschreiten.<br/>
                                                <br/>
                                                Auf allen Flügen, die in der EU starten "auch auf
                                                Inlandsflügen" dürfen Flüssigkeiten nur noch in
                                                geringen Mengen durch die Sicherheitskontrolle
                                                mitgenommen werden.<br/>
                                                <br/>
                                                Bitte beachten Sie hierzu die EU-Sicherheitsbestimmungen
                                                auf Flughäfen unter <a
                                                href="https://www.globalis.de/sicherheit"
                                                target="_blank">www.globalis.de/sicherheit</a>.
                                            </p>

                                        </div>
                                    </div>
                                </div>


                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading14">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse14" aria-expanded="false"
                                                aria-controls="collapse14">
                                            14. Plattform zur Online-Streitbeilegung
                                        </button>
                                    </h2>
                                    <div id="collapse14" className="accordion-collapse collapse"
                                         aria-labelledby="heading14"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                Online-Streitbeilegung gemäß
                                                Art. 14 Abs. 1 ODR-VO: Die Europäische Kommission stellt
                                                eine Plattform zur Online-Streitbeilegung (OS) bereit,
                                                die Sie unter folgendem Link finden:<br/>
                                                <br/>
                                                <a href="http://ec.europa.eu/consumers/odr/"
                                                   target="_blank">www.ec.europa.eu/consumers/odr</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="row pt-5">
                            <h2><strong>Haben Sie weitere Fragen?</strong></h2>
                        </div>
                        <div className="row pt-5">
                            <div className="col-md-6 text-left">
                                <aside className="widget widget_text">
                                    <img src={require('../images/Call.jpg')}
                                         className="img-circle img-responsive"
                                         alt=""/>
                                </aside>
                            </div>

                            <div className="col-md-6 text-left">
                                <p>Rufen Sie uns einfach an:<br/>
                                    Telefon: <a href={`tel:${formatPhoneNumber(partner.phone)}`}>{getPhoneNumber(partner.phone)}</a>
                                    <br/>
                                    Mo. – Fr. 09:00 – 18:00 <br/>
                                    Sa. 10:00 – 14:00 <br/>
                                    So. 10:00 – 14:00 <br/>
                                </p>
                                <p>
                                    Natürlich können Sie uns auch eine
                                    <a href={`mailto:${partner.email}`}><strong> e-Mail </strong></a>
                                    schreiben <br/> oder verwenden
                                    Sie das <a href="/kontakt"><strong>Kontaktformular</strong></a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default FAQ;