import React, {useCallback, useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import Fetching from "./fetching";
import {allTripTypes, fetchDestinations, mapTripType, monthNames} from "./utils/utils";
import HeaderSubPage from "./partial/HeaderSubPage";
import CalendarBody from "./partial/calendarBody";
import InfiniteScroll from "react-infinite-scroller";
import axiosConfig from "./utils/axiosConfig";

const bgImage = process.env.PUBLIC_URL + "/images/headers/Reisekalender-min.png"

const Calendar = () => {

    const [searchParams] = useSearchParams();
    const [search, setSearch] = useState(searchParams.get("s") ?? "");
    const [type, setType] = useState(searchParams.get("reiseart") ?? "");
    const [dest, setDest] = useState(searchParams.get("reiseziel") ?? "");

    const [calendarYearMonth, setCalendarYearMonth] = useState([]);
    const [destinations, setDestinations] = useState([]);

    const [nextPage, setNextPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [fetching, setFetching] = useState(false);

    const fetchCalendarYearMonth = useCallback(async () => {
        if (fetching) return;

        setFetching(true);
        try {
            const cType = mapTripType(type);
            const response = await axiosConfig.get(`/agency/${process.env.REACT_APP_AGENCY_NO}/calendar-months?type=${cType}&dest=${dest}&search=${search}&page=${nextPage}`);

            setCalendarYearMonth([...calendarYearMonth, ...response.data.data])
            setNextPage(response.data.current_page + 1)
            setLastPage(response.data.last_page)
        } catch (error) {
            console.error("Error fetching data:", error);
            return [];
        } finally {
            setFetching(false);
        }

    }, [nextPage, lastPage, fetching]);

    const hasMoreItems = nextPage <= lastPage;

    // Call fetchData on component mount
    useEffect(() => {
        fetchDestinations().then((data) => {
            setDestinations(data);

        });
    }, []);

    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Reisekalender'} subtitle={'Reisekalender'} bgImage={bgImage}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="widget-area col-md-3 col-sm-12">
                                <div className="search_tour">
                                    <div className="form-block block-after-indent">
                                        <h3 className="form-block_title">Reisefilter</h3>
                                        <div className="form-block__description">Finden Sie Ihre Traumreise jetzt!
                                        </div>
                                        <form method="get" action="#">
                                            <input type="hidden" name="tour_search" value="1"/>
                                            <input type="text" className="form-control" id="s" name="s"
                                                   placeholder="Suchbegriff eingeben..."
                                                   value={search}
                                                   onChange={(e) => setSearch(e.target.value)}/>
                                            <select className="form-select" name="reiseart"
                                                    value={type}
                                                    onChange={(e) => setType(e.target.value)}>
                                                <option value="">Reiseart</option>
                                                {allTripTypes.map((post, i) => (
                                                    <option value={post.title} key={i}>{post.title}</option>
                                                ))}
                                            </select>
                                            <select className="form-select" name="reiseziel"
                                                    value={dest}
                                                    onChange={(e) => setDest(e.target.value)}>
                                                <option value=""> Reiseziel</option>
                                                {destinations?.map((post, x) => (
                                                    <option value={post.Kontinent_2012}
                                                            key={x}>{post.Kontinent_2012}</option>
                                                ))}
                                            </select>
                                            <button type="submit">Suchen</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12">
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <InfiniteScroll
                                            loadMore={fetchCalendarYearMonth}
                                            hasMore={hasMoreItems}
                                            loader={<Fetching isFetching={fetching}></Fetching>}
                                        >
                                            {calendarYearMonth.map((row, rowIndex) => (
                                                <div className="accordion-item" key={rowIndex}>
                                                    <h2 className="accordion-header"
                                                        id={'panelsStayOpen-heading' + rowIndex}>
                                                        <button
                                                            id={'accordion-button' + rowIndex}
                                                            className={'accordion-button ' + (rowIndex === 0 ? '' : 'collapsed')}
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={'#panelsStayOpen-collapse' + rowIndex}
                                                            aria-expanded={rowIndex === 0}
                                                            aria-controls={'panelsStayOpen-collapse' + rowIndex}>
                                                            {monthNames[Number(row.month) - 1]} {row.year}
                                                        </button>
                                                    </h2>
                                                    <div id={'panelsStayOpen-collapse' + rowIndex}
                                                         className={'accordion-collapse collapse ' + (rowIndex === 0 ? 'show' : '')}
                                                         aria-labelledby={'panelsStayOpen-heading' + rowIndex}>
                                                        <CalendarBody month={row.month} year={row.year}
                                                                      search={search}
                                                                      shown={document.getElementById('accordion-button' + rowIndex)?.getAttribute("aria-expanded")}
                                                        ></CalendarBody>
                                                    </div>
                                                </div>
                                            ))}
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )


}

export default Calendar;