import React from 'react';
import HeaderSubPage from "./partial/HeaderSubPage";
import {allTripDestinations} from "./utils/utils";
import {BrowserView, MobileView} from "react-device-detect";
const bgImage = process.env.PUBLIC_URL + "/images/headers/Reiseziele-min.png"

const AllDestination = () => {
    return (
        <div>

            <div className="site wrapper-content">
                <HeaderSubPage title={'Reiseziele'} subtitle={'Reiseziele'} bgImage={bgImage}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <header><h1 className=""> Unsere Reiseziele </h1></header>
                                <p>Aufgrund der Vielzahl unserer Reisen und Reisethemen haben wir unser Programm
                                    kategorisiert, um Ihnen die Unterschiede noch einfacher darzustellen. Die einzelnen
                                    Kategorien können Sie bequem über den <a href="/reisefinder/"><span
                                        className="color-primary"><strong>Reisefinder</strong></span></a> auswählen.
                                    Somit
                                    bieten wir Ihnen einen schnellen und einfachen Zugang zu Ihrer gewünschten Kategorie
                                    und
                                    Reiseart.</p></div>
                        </div>
                        <div className="clearfix"></div>
                        <MobileView>
                            {allTripDestinations.map((post, index) =>
                                <div className="row equal pt-5" key={index}>
                                    <div className="col-md-6  order-md-2 how-img">
                                        <img src={post.image} className="img-fluid" alt=""/>
                                    </div>
                                    <div className="col-md-6 order-md-1"
                                         style={{padding: "30px", backgroundColor: "lightgoldenrodyellow"}}>
                                        <h4>{post.title}</h4>
                                        <p className="text-muted">{post.description}</p>
                                        <p><a
                                            href={`/reiseziele/${encodeURIComponent(post.title)}?kontinent=${post.continent}`}>Zu
                                            den
                                            Reisen</a>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </MobileView>
                        <BrowserView>
                            {allTripDestinations.map((post, index) =>
                                index % 2 === 0 ?
                                    <div className="row equal pt-5" key={index}>
                                        <div className="col-md-6 how-img">
                                            <img src={post.image} className="img-fluid" alt=""
                                                 style={{height: "100%"}}/>
                                        </div>
                                        <div className="col-md-6"
                                             style={{padding: "30px", backgroundColor: "lightgoldenrodyellow"}}>
                                            <h4>{post.title}</h4>
                                            <p className="text-muted">{post.description}</p>
                                            <p><a
                                                href={`/reiseziele/${encodeURIComponent(post.title)}?kontinent=${post.continent}`}>Zu
                                                den
                                                Reisen</a>
                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <div className="row equal pt-5" key={index}>
                                        <div className="col-md-6"
                                             style={{padding: "30px", backgroundColor: "lightgoldenrodyellow"}}>
                                            <h4>{post.title}</h4>
                                            <p className="text-muted">{post.description}</p>
                                            <p><a
                                                href={`/reiseziele/${encodeURIComponent(post.title)}?kontinent=${post.continent}`}>Zu
                                                den
                                                Reisen</a>
                                            </p>
                                        </div>
                                        <div className="col-md-6 how-img">
                                            <img src={post.image} className="img-fluid" alt=""
                                                 style={{height: "100%"}}/>
                                        </div>
                                    </div>)}
                        </BrowserView>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default AllDestination;
