import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Step2 from "./partial/booking/Step2";
import Step3 from "./partial/booking/Step3";
import Step4 from "./partial/booking/Step4";
import Step5 from "./partial/booking/Step5";
import {
    fetchBookingAdvancedServices,
    fetchBookingGrundpreis,
    fetchCountries,
    fetchIncludedServices,
    fetchTransactionAccommodations,
    fetchTransactionsDetails,
    notify
} from "./utils/utils";
import Step1 from "./partial/booking/Step1";
import Loading from "./partial/Loading";
import axiosConfig from "./utils/axiosConfig";
import ReCAPTCHA from 'react-google-recaptcha'

const MultiStepBookingForm = () => {
        const methods = useForm();
        const domain = window.location.hostname
        const {
            register,
            formState: {errors},
            trigger,
            handleSubmit,
            clearErrors,
            getValues,
            reset,
            getFieldState,
            watch,
            setError
        } = methods

        const paymentMethod = watch("payment.method", 1)
        const [searchParams] = useSearchParams();
        const transactionNo = searchParams.get("tNo");
        const tripId = searchParams.get("id");
        const navigate = useNavigate();

        const [countries, setCountries] = useState([]);
        const [transactionDetails, setTransactionDetail] = useState([]);
        const [availableAccommodations, setAvailableAccommodations] = useState([]);
        const [grundpreis, setGrundpreis] = useState([]);
        const [advancedServices, setAdvancedServices] = useState([]);
        const [includedServices, setIncludedServices] = useState([]);
        const [isLoading, setIsLoading] = useState(true);

        const mapPostToRequest = (data) => {

            const occupations = []

            data.occupations?.map((occupation) => {
                const participants = []
                occupation.participants?.map((participant) => {
                    const services = []

                    participant.services?.map((service) => {
                        services.push({
                            "id": service.id,
                            "bezeichnung": service.bezeichnung,
                            "value": service.value,
                            "vk_preis": service.vk_preis
                        })
                    })

                    participants.push({
                        "gender": participant.gender,
                        "firstname": participant.firstname,
                        "lastname": participant.lastname,
                        "services": services
                    })
                })

                occupations.push({
                    "vuId": occupation.vuId,
                    "suId": occupation.suId,
                    participants: participants,

                });
            });

            return {
                "bookingSource": `online - ${domain}`,
                "coupon": data.coupon,
                "newsletter": data.newsletter,
                "remark": data.remark,
                "transaction": {
                    "id": data.transaction.id,
                    "tripId": data.transaction.tripId,
                },
                "customer": {
                    "title": data.customer.title,
                    "gender": data.customer.gender,
                    "firstname": data.customer.firstname,
                    "lastname": data.customer.lastname,
                    "email": data.customer.email,
                    "postalCode": data.customer.postalCode,
                    "streetAndNo": data.customer.streetAndNo,
                    "country": data.customer.country,
                    "city": data.customer.city,
                    "phone": data.customer.phone,
                    "mobile": data.customer.mobile,
                },
                "payment": {
                    "method": data.payment.method,
                    "bank": data.payment.bank,
                    "owner": data.payment.owner,
                    "iban": data.payment.iban,
                },
                "occupations": occupations
            };
        }
        const doBooking = async (data) => {
            const request = mapPostToRequest(data);
            try {
                const response = await axiosConfig.post('/booking', request,
                    {'Content-Type': 'application/json'});
                return response.data
            } catch (error) {
                //console.log(error);
                throw error
            }
        }

        useEffect(() => {
            fetchTransactionsDetails(transactionNo).then((data) => {
                setTransactionDetail(data);
            }).then(() => {
                fetchCountries().then((data) => {
                    setCountries(data);
                }).then(() => {
                    fetchTransactionAccommodations(transactionNo).then((data) => {
                        setAvailableAccommodations(data);
                    }).then(() => {
                        fetchBookingGrundpreis(transactionNo).then((data) => {
                            setGrundpreis(data);
                        }).then(() => {
                            fetchBookingAdvancedServices(transactionNo).then((data) => {
                                setAdvancedServices(data);
                            }).then(() => {
                                fetchIncludedServices(tripId).then((data) => {
                                    setIncludedServices(data);
                                }).then(() => {
                                    setIsLoading(false);
                                })
                            })
                        })
                    })
                });
            })
        }, []);

        const fields = [];
        for (let i = 0; i < availableAccommodations.length; i++) {
            fields.push(`accommodations.${i}`)
        }

        const [step, setStep] = useState(1);

        const handleNext = () => {
            //console.log(getValues());
            //console.log(JSON.stringify(getValues()));
            // console.log(fields);
            // console.log(errors);
            if (step === 2) {
                fields.forEach((field) => {
                    trigger([field]).then((check) => {
                        if (check) {
                            clearErrors("accommodations")
                            setStep(step + 1);
                        }
                    });
                })
            } else if (step === 3) {
                trigger(["occupations"]).then((check) => {
                    if (check) {
                        setStep(step + 1);
                    }
                });
            } else if (step === 4) {
                trigger(["customer"]).then((check) => {
                    if (check) {
                        setStep(step + 1);
                    }
                });
            } else if (step === 5) {
                trigger(["payment", "agb"]).then((check) => {
                    if (check) {
                        setStep(step + 1);
                    }
                });
            } else {
                trigger().then((check) => {
                    setStep(step + 1);
                });
            }
        };

        const handlePrevious = () => {
            setStep(step - 1);
        };

        const onSubmit = (data) => {
            setIsLoading(true);
            trigger().then((check) => {
                //console.log(data)
                if (check) {
                    doBooking(data).then((response) => {
                        reset();
                        setIsLoading(false);
                        navigate("/booked?tNo=" + transactionNo, {state: response});
                    }).catch((error) => {
                        setIsLoading(false);
                        notify(error.message)
                    });
                }
            })
        };

        return (
            <div className="row">
                <ToastContainer/>
                <div className="col-lg-3 col-sm-12">
                    <ul className="list-group mb-3">
                        <li className="list-group-item d-flex justify-content-between align-items-center nav-item">
                            Ausgewählte Reise
                            {step >= 1 ?
                                <i className="fa fa-check-circle"></i>
                                :
                                <i className="fa fa-clock-o text-secondary"></i>
                            }
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center nav-item">
                            Unterkünfte & Leistungen
                            {step >= 2 ?
                                <i className="fa fa-check-circle"></i>
                                :
                                <i className="fa fa-clock-o text-secondary"></i>
                            }
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center nav-item">
                            Teilnehmer Eingabe
                            {step >= 3 ?
                                <i className="fa fa-check-circle"></i>
                                :
                                <i className="fa fa-clock-o text-secondary"></i>
                            }
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center nav-item">
                            Kundendaten
                            {step >= 4 ?
                                <i className="fa fa-check-circle"></i>
                                :
                                <i className="fa fa-clock-o text-secondary"></i>
                            }
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center nav-item">
                            Zahlung & Bestätigung
                            {step >= 5 ?
                                <i className="fa fa-check-circle"></i>
                                :
                                <i className="fa fa-clock-o text-secondary"></i>
                            }
                        </li>
                    </ul>
                </div>
                <div className="site-main col-lg-9 col-sm-12">
                    <div className="entry-content">
                        <div className="travel_tour">
                            <Loading isLoading={isLoading}/>
                            <FormProvider {...methods}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="progress mb-3" role="progressbar" aria-label="Warning example"
                                         aria-valuenow="75"
                                         aria-valuemin="0" aria-valuemax="100">
                                        <div className="progress-bar"
                                             style={{width: (step / 5) * 100 + "%"}}></div>
                                    </div>
                                    {Object.keys(errors).length !== 0 &&
                                        <p className="error">Achtung Bitte überprüfen und vervollständigen Sie Ihre
                                            Angaben. </p>}
                                    {step === 1 && (
                                        <Step1
                                            isLoading={isLoading}
                                            includedServices={includedServices}
                                            transactionDetails={transactionDetails}
                                        />
                                    )}
                                    {step === 2 && (
                                        <Step2
                                            includedServices={includedServices}
                                            availableAccommodations={availableAccommodations}
                                            transactionDetails={transactionDetails}
                                            grundpreis={grundpreis}
                                            advancedServices={advancedServices}
                                        />
                                    )}
                                    {step === 3 && (
                                        <Step3
                                            availableAccommodations={availableAccommodations}
                                            transactionDetails={transactionDetails}
                                            grundpreis={grundpreis}
                                        />
                                    )}
                                    {step === 4 && (
                                        <Step4
                                            countries={countries}
                                        />
                                    )}
                                    {step === 5 && (
                                        <Step5
                                            tripId={tripId}
                                            stateChanger={setStep}
                                            transactionDetails={transactionDetails}
                                        />
                                    )}
                                    <div className="pt-5">
                                        {step > 1 && step <= 4 && (
                                            <button type="button" className="btn btn-primary float-start"
                                                    onClick={handlePrevious}>Zurück</button>
                                        )}
                                        {step <= 4 ? (
                                            <button type="button" className="btn btn-primary float-end"
                                                    onClick={handleNext}>Weiter</button>
                                        ) : (
                                            <div>
                                                {isLoading ? (
                                                    <button type="submit" className="btn btn-primary talign-center"
                                                            disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status"
                                                          aria-hidden="true"></span>
                                                        zahlungspflichtig buchen
                                                    </button>) : (
                                                    <button type="submit"
                                                            className="btn btn-primary talign-center">Jetzt
                                                        zahlungspflichtig buchen</button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        size="invisible"/>
                                </form>
                            </FormProvider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
;

export default MultiStepBookingForm;